<svelte:options customElement="clever-search-fp" />

<script lang="ts">
    /** stores */
    import { ConfigStore } from "./lib/store/config";
    import { state_busyApi, state_searchResult } from "./lib/store/state";
    /** others */
    import { fade, fly } from "svelte/transition";
    import { ApiTypes } from "./lib/enums/api.enum";
    import { WidgetType } from "./lib/enums/widget-type.enum";
    import { shadowDomUpdates } from "./lib/helpers/shadowdom-updates";
    import { onMount } from "svelte";

    import SearchInputComponent from "./lib/components/search-input.svelte";
    import FaqComponent from "./lib/components/faq.svelte";
    import ResultsComponent from "./lib/components/result-wrapper.svelte";

    $ConfigStore = { ...$ConfigStore, widgetType: WidgetType.fb };

    $: isSearchBusy = $state_busyApi.includes(ApiTypes.postQuestion);

    $: logoSrc = $ConfigStore.logoMain.includes("http")
        ? $ConfigStore.logoMain
        : ($ConfigStore.assetBaseUrl || $ConfigStore.apiBaseUrl) +
          $ConfigStore.logoMain;

    $: isResult =
        !!$state_searchResult?.answer ||
        $state_busyApi.includes(ApiTypes.postQuestion);

    onMount(() => {
        shadowDomUpdates("clever-search-fp", true);
    });
</script>

{#if !!$ConfigStore.apiBaseUrl}
    <div class="cs-wrapper cs-full-page">
        <div
            class="cs-search-toolbar-center"
            class:top={$state_searchResult?.answer || isSearchBusy}
        >
            <div
                class="cs-search-toolbar-wrapper"
                in:fly|global={{ y: 50, duration: 1000 }}
                out:fade|global
            >
                <div class="logo">
                    <img src={logoSrc} alt={$ConfigStore.productName} />
                </div>
                <div>
                    <SearchInputComponent />
                </div>
            </div>
            {#if !isResult}
                <div in:fly|global={{ y: 50, duration: 1000 }}>
                    <FaqComponent />
                </div>
            {/if}
            <ResultsComponent />
        </div>
    </div>
{/if}

{#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}

<style lang="scss">
    @import "./scss/pages/full-page";
</style>
