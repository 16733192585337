<!-- <svelte:options customElement="clever-search-result" /> -->

<script lang="ts">
    import { _ } from "svelte-i18n";
    import { fade } from "svelte/transition";
    import { ApiTypes } from "../enums/api.enum";
    import { HandledErrorTypes } from "../enums/handled-errors.enum";
    import { ConfigStore } from "../store/config";
    import {
        state_apiErrors,
        state_busyApi,
        state_questionRelatedSearchSuggestions,
        state_searchResult
    } from "../store/state";
    import BusyComponent from "./busy.svelte";
    import QuestionRelatedSuggestionComponents from "./question-related-search.svelte";
    import ResultContentComponent from "./result-content.svelte";

    $: isSearchBusy = $state_busyApi.includes(ApiTypes.postQuestion);
    $: postQuestionApiError = $state_apiErrors.find(
        (x) => x.apiType == ApiTypes.postQuestion
    );

    /** search bar top moving animation on the first search instance has some glitch
     * in UI when data is received before animation completed. fixing by
     *  initially setting a timeout to wait for the animation completed.
     * */
    let showContentAfterAnimation = null;

    $: showContentAfterAnimationFn(isSearchBusy);

    // onMount(() => {
    //     shadowDomUpdates("clever-search-result", true);
    // });

    const showContentAfterAnimationFn = (x: boolean) => {
        if (!x || showContentAfterAnimation !== null) return;

        showContentAfterAnimation = false;

        setTimeout(() => {
            showContentAfterAnimation = true;
        }, 1000);
    };
</script>

{#if isSearchBusy || showContentAfterAnimation == false}
    <BusyComponent />
{:else if postQuestionApiError && showContentAfterAnimation}
    {#if postQuestionApiError.error == HandledErrorTypes.GPTContentFilterError}
        <div class="cs-error-msg">
            {$_("chat_wrapper.error_content_comply")}
        </div>
    {:else}
        <div class="cs-error-msg">
            {$_("result_wrapper.search_not_working")}
        </div>
    {/if}
{:else if !!$state_searchResult?.answer && !isSearchBusy}
    <div class="cs-search-result-outer-wrapper" in:fade|global>
        <ResultContentComponent />
        {#if $state_searchResult.answer}
            {#if $ConfigStore.serverConfig?.question_prompts?.includes("suggested_search") && $state_questionRelatedSearchSuggestions}
                <QuestionRelatedSuggestionComponents />
            {/if}
        {/if}
    </div>
{/if}

<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if} -->

<style lang="scss">
    @import "./../../scss/shared/_error";
    @import "./../../scss/components/result-wrapper";

    .cs-error-msg {
        margin: 20px 0;
    }
</style>
