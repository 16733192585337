/** enum to store api types */
export enum ApiTypes {
    postQuestion = 'postQuestion',
    putRating = 'putRating',
    getIndex = 'getIndex',
    getThread = 'getThread',
    postSearch = 'postSearch',
    getSuggestions = 'getSuggestions',
    getQuestionRelatedSuggestions = 'getQuestionRelatedSuggestions',
    getServerConfig = 'getServerConfig',
    postServerInit = 'postServerInit',
    postOption = 'postOption',
    getOption = 'getOption',

    streamPOSTQuestion = 'StreamPOSTQuestion',
}