import { get } from "svelte/store";
import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
import type { IConfigStore } from "../interfaces/store-config.interface";
import { ConfigStore } from "../store/config";
import { state_alternativeSearchResults, state_answerRating, state_apiErrors, state_busyApi, state_conversationResults, state_faq, state_indicesList, state_questionRelatedSearchSuggestions, state_searchResult, state_searchString, state_searchSuggestion, state_selectedIndicesList, state_showAlternative, state_showSearch, state_showBadge, state_sessionStorage } from "../store/state";
import { updateConfigStore } from "./update-config-store";
import { SessionStoreHandler } from "./session-storage-handler";
import { defaultLogger as logger } from "../telemetry/logger";

/** all window custom events are mapped to csSearch  in window object  */
export function WindowIntegrations() {

    window['gqa'] = window['gqa'] || {};

    initWindowListeners();

    /** get calls */
    mapGetState();
    mapGetConfigStore();

    /*** actions */
    mapToggle();
    mapUpdateConfig();
    mapShowToggle();
    mapShowGreetingPopup();

    mapInit();

    function mapGetState(): void {
        window['gqa'].getState = () => {
            // method returns the current state of the widget
            return {
                showSearch: get(state_showSearch),
                busyApi: get(state_busyApi),
                apiErrors: get(state_apiErrors),
                searchString: get(state_searchString),
                searchResult: get(state_searchResult),
                conversationResults: get(state_conversationResults),
                searchSuggestion: get(state_searchSuggestion),
                faq: get(state_faq),
                indicesList: get(state_indicesList),
                selectedIndicesList: get(state_selectedIndicesList),
                alternativeSearchResults: get(state_alternativeSearchResults),
                showAlternative: get(state_showAlternative),
                answerRating: get(state_answerRating),
                questionRelatedSearchSuggestions: get(state_questionRelatedSearchSuggestions),
                showBadge: get(state_showBadge),
                sessionStorage: get(state_sessionStorage)
            };
        }
    }

    function mapGetConfigStore(): void {
        window['gqa'].getConfig = () => {
            // function returns the current config of the widget
            return { ...get(ConfigStore) };
        }
    }

    function mapToggle() {
        window['gqa'].toggleView = (show: boolean) => {
            // function update to the toggle state
            // toggleView(show?: boolean)
            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.toggleView,
                    show != undefined ? { detail: show } : null))
        }
    }

    function mapShowToggle() {
        window['gqa'].showBadge = (show: boolean) => {
            // function update to the visibility of toggle component
            // showBadge(show?: boolean)
            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.showBadge,
                    show != undefined ? { detail: show } : null))
        }
    }

    function mapUpdateConfig() {

        window['gqa'].updateConfig = (x: IConfigStore) => {
            // function returns the current configuration of the widget
            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.updateConfig, { detail: x }))
        };

    }

    function mapShowGreetingPopup() {
        window['gqa'].showGreetingPopup = () => {
            if (!get(ConfigStore).greetingOnToggle)
                return console.error('greetingOnToggle is not enabled in config');

            SessionStoreHandler.remove('offGrtTs');

            if (!get(state_showBadge)) {
                return console.error('Greeting popup is not shown as the toggle is not visible. Greeting Session storage is cleared');
            }
            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.showGreetingPopup));
        };
    }

    function mapInit() {
        window['gqa'].init = (intiParams: {
            configOptions: IConfigStore,
            serverConfig: { config: string; tenant: string }
        }) => {
            if (!intiParams?.configOptions?.apiBaseUrl)
                return console.error(`Invalid argument to initiate the search. apiBaseUrl is required.
                ex: {configOptions: {apiBaseUrl: 'xxxx'}}`);

            // function returns the current configuration of the widget
            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.requestPostOption, { detail: intiParams }));

            window['gqa'].init = () => {
                return console.error('GQA widget already initiated');
            };

            return true;
        };
    }


    /** listed to window even listeners */
    function initWindowListeners() {
        [
            WindowsCustomEventTypes.toggleView,
            WindowsCustomEventTypes.updateConfig,
            WindowsCustomEventTypes.showBadge
        ].forEach(evt =>
            window.addEventListener(evt, initWindowListenersHandler)
        );
    }


    /** method to handle window event listeners */
    function initWindowListenersHandler(e): void {
        try {
            switch (e.type) {

                case WindowsCustomEventTypes.toggleView:
                    if (!(typeof e?.detail == "boolean" || e?.detail == undefined)) {
                        return console.error(`
                        Invalid argument. Should be empty or boolean. 
                        ex: toggleView(true) or toggleView()`);
                    }
                    const showSearch = typeof e?.detail == "boolean" ? e?.detail : !get(state_showSearch);
                    state_showSearch.set(showSearch);
                    SessionStoreHandler.update({ visible: showSearch }); // persist to session storage
                    logger.trackEvent("Toggle UI Display", { showWidget: showSearch, tenant: get(ConfigStore)?.serverConfig?.tenant_id });
                    break;
                case WindowsCustomEventTypes.updateConfig:
                    updateConfigStore(e.detail);
                    window.dispatchEvent(
                        new CustomEvent(WindowsCustomEventTypes.requestIndices));
                    window.dispatchEvent(
                        new CustomEvent(WindowsCustomEventTypes.requestServerConfig, { detail: e?.detail?.serverConfig }));
                    logger.trackEvent("Update Widget Config", { config: e.detail, tenant: get(ConfigStore)?.serverConfig?.tenant_id });
                    break;
                case WindowsCustomEventTypes.showBadge:
                    if (!(typeof e?.detail == "boolean" || e?.detail == undefined)) {
                        return console.error(`
                        Invalid argument. Should be empty or boolean. 
                        ex: showToggle(true) or showToggle()`);
                    }
                    const showBadge = typeof e?.detail == "boolean" ? e?.detail : !get(state_showBadge);
                    state_showBadge.set(showBadge);
                    logger.trackEvent("Toggle Badge Display", { showBadge, tenant: get(ConfigStore)?.serverConfig?.tenant_id });
                    break;
                default:
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }


}