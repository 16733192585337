
import { state_answerRating } from "../../store/state";
import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { WindowsCustomEventTypes } from "../../enums/windows-custom-event.enum";
import type { IUpdateRating } from "../../interfaces/request-update-rating-question.interface";
import { state_busyApi } from "../../store/state";
import { defaultLogger as logger } from "../../telemetry/logger";
import { PutRating } from "../api/put-rating";

export class RatingHelper {

    private abortController;

    constructor() {

        this.abort();
    }

    public async update(params: IUpdateRating) {

        let reqObj: IUpdateRating;
        let questionId;

        try {

            const reqProperties = ['type', 'score', 'questionId']

            if (typeof params !== 'object' || !reqProperties.every(function (x) { return x in params; }))
                return console.error('RatingHelper/update - incomplete argument', params);

            questionId = params.questionId;

            delete params.questionId;
            reqObj = params;

        } catch (e) {
            logger.warn('RatingHelper/update - invalid argument', e)
            return;
        }

        try {
            this.abortController?.abort();
            this.abortController = new AbortController();
            const res = await PutRating(reqObj, questionId, this.abortController.signal);

            if (res?.ok) {
                window.dispatchEvent(
                    new CustomEvent(WindowsCustomEventTypes.UpdateRatingStatus, {
                        detail: true,
                    })
                );
                state_answerRating.set({ ...params, questionId })

            } else {

                window.dispatchEvent(
                    new CustomEvent(WindowsCustomEventTypes.UpdateRatingStatus, {
                        detail: false,
                    })
                );

                state_answerRating.set(null)

            }

        } catch (e) {

            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.UpdateRatingStatus, {
                    detail: false,
                })
            );

            state_answerRating.set(null)

            logger.error('RatingHelper/update', e)
        }
    }


    /** method to abort pending operation */
    private abort(): void {

        /** check for type of action */
        const abortNow = (e): void => {
            if (get(state_busyApi).includes(ApiTypes.putRating))
                this.abortController?.abort();
        }

        [WindowsCustomEventTypes.requestPostOption].forEach(evt =>
            window.addEventListener(evt, abortNow)
        );
    }

}