
import { get } from "svelte/store";
import type { IRequestSearchAlternative } from "../../interfaces/request-search-alternative.interface";
import type { IRequestSearch } from "../../interfaces/request-search-question.interface";
import { ConfigStore } from "../../store/config";
import { state_alternativeSearchResults } from "../../store/state";
import { defaultLogger as logger } from "../../telemetry/logger";
import { PostSearch } from "../api/post-search";


export class AlternativeHelper {

    constructor() { }

    public async get(params: IRequestSearch) {

        try {
            let reqObj: IRequestSearchAlternative = {
                query: params.query,
                max_hits: get(ConfigStore).alternativeReqCount
            };

            if (params.index) reqObj.index = params.index;

            if (!!get(ConfigStore)?.serverConfig?.id) reqObj.config = get(ConfigStore)?.serverConfig?.id;

            const res = await PostSearch(reqObj);

            if (res?.ok) {
                const data: any = await res.json();
                state_alternativeSearchResults.set(data.hits);
            }

        } catch (e) {
            logger.error('AlternativeHelper/get', e)
        }


    }

}