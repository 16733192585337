import { v4 as uuidv4 } from 'uuid';
import { defaultLogger as logger } from './logger';
import type { IDependencyTelemetry } from '@microsoft/applicationinsights-web';

export function trackedFetch(tenantId: string, input: RequestInfo, init : RequestInit = {}) {
    // Get the URL string from either a string input or Request object
    const url = typeof input === 'string' ? input : input.url;
    
    // Parse the URL to get components
    const parsedUrl = new URL(url);
    
    // Get start time
    const startTime = Date.now();

    // Start the dependency tracking
    const dependency: IDependencyTelemetry = {
        id: uuidv4(),
        name: `${init.method || 'GET'} ${parsedUrl.origin}${parsedUrl.pathname}`,
        startTime: new Date(),
        target: url,
        data: url,
        duration: 0,
        responseCode: 0,
        success: false,
        type: 'Fetch',
        properties: {
            HttpMethod: init.method || 'GET',
            tenant: tenantId
        }
    };

    //logger.trackDependency(dependency);

    // Execute the fetch
    return fetch(input, init)
        .then(response => {
            // Calculate duration
            const duration = Date.now() - startTime;

            // Track the dependency
            logger.trackDependency({
                id: dependency.id,
                name: dependency.name,
                startTime: dependency.startTime,
                duration: duration,
                success: response.ok,
                responseCode: response.status,
                type: dependency.type,
                target: dependency.target,
                data: dependency.data,
                properties: dependency.properties
            });

            return response;
        })
        .catch(error => {
            // Calculate duration
            const duration = Date.now() - startTime;

            // Track the dependency with error
            logger.trackDependency({
                id: dependency.id,
                name: dependency.name,
                startTime: dependency.startTime,
                duration: duration,
                success: false,
                responseCode: 500,
                type: dependency.type,
                target: dependency.target,
                data: dependency.data,
                properties: {
                    ...dependency.properties,
                    error: error.message,
                    body: init.body
                }
            });

            throw error; // Re-throw the error to maintain the promise chain
        });
}
