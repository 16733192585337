<script lang="ts">
    /** store*/
    import { ConfigStore } from "../store/config";
    import {
        state_apiErrors,
        state_busyApi,
        state_conversationResults,
        state_searchResult,
        state_searchString,
        state_searchSuggestion,
        state_selectedIndicesList,
    } from "../store/state";

    import { onMount } from "svelte";
    import Device from "svelte-device-info";
    import { fade } from "svelte/transition";
    import { ApiTypes } from "../enums/api.enum";
    import { WidgetType } from "../enums/widget-type.enum";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import type { IRequestSearch } from "../interfaces/request-search-question.interface";
    import type { IRequestSearchSuggestion } from "../interfaces/request-search-suggestion.interface";

    import InputSpeechRecognitionComponent from "./search-input-speech-recognition.svelte";
    import SuggestionComponent from "./search-input-suggestion.svelte";

    import { _ } from "svelte-i18n";

    let txtSearch;
    let searchSuggestionList: HTMLElement;
    let suggestionTimer: any;

    let isMinStringMsg: boolean;
    let isAutoSuggestionUpNow;

    $: isSearchBusy = $state_busyApi.includes(ApiTypes.postQuestion);

    $: isAutoSuggestionUp();

    state_apiErrors.subscribe((x) => {
        const postQuestionError = x.find((x) =>
            [ApiTypes.postQuestion, ApiTypes.streamPOSTQuestion].includes(
                x.apiType,
            ),
        );

        if (postQuestionError?.errorType == "AbortError") return;

        if (postQuestionError && $state_conversationResults.length) {
            state_searchString.set(
                $state_conversationResults[
                    $state_conversationResults.length - 1
                ].question,
            );
        }
    });

    window.addEventListener(
        WindowsCustomEventTypes.speechSearchRequest,
        (e) => {
            speechChange(e);
        },
    );

    onMount(() => {
        if ($ConfigStore.widgetType !== WidgetType.chat) {
            if (!Device.isPhone) {
                txtSearch?.focus();
            }
        }

        searchOnLoadWithUrlParam();

        // shadowDomUpdates("clever-search-input", true);
    });

    const searchOnLoadWithUrlParam = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const question = urlParams.get("gqa_q");

        if (!!question?.length)
            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.requestSearch, {
                    detail: {
                        query: question,
                        flags: $ConfigStore.customAttributes?.flags,
                        cookies: $ConfigStore.customAttributes?.cookies,
                        url: $ConfigStore.customAttributes?.url,
                    },
                }),
            );
    };

    /** method to trigger search request action*/
    const search = () => {
        if (!$state_searchString?.length) return;

        /** current result search and requesting search string is same*/
        if (
            $state_searchString === $state_searchResult?.question &&
            [WidgetType.chat, WidgetType.cb].includes($ConfigStore.widgetType)
        )
            return;

        if ($ConfigStore.searchStringMinLength >= $state_searchString?.length) {
            isMinStringMsg = true;
            return;
        }

        isMinStringMsg = false;

        const data: IRequestSearch = {
            query: $state_searchString,
            flags: $ConfigStore.customAttributes?.flags,
            cookies: $ConfigStore.customAttributes?.cookies,
            url: $ConfigStore.customAttributes?.url,
        };

        if ($state_selectedIndicesList.length) {
            data.index = $state_selectedIndicesList
                .toString()
                .replace(", ", "");
        }

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.requestSearch, {
                detail: data,
            }),
        );

        if (![WidgetType.chat, WidgetType.cb].includes($ConfigStore.widgetType))
            state_searchString.set(data.query);
    };

    const handleOnKeyUp = (e) => {
        if (!$state_searchString?.length && $ConfigStore.searchSuggestion) {
            state_searchSuggestion.set([]);
            return;
        }

        switch (e.key) {
            case "Enter":
                if (searchSuggestionList?.checkVisibility()) return;
                search();
                break;
            default:
                break;
        }

        getSuggestions();
    };

    const getSuggestions = (): void => {
        if (!$ConfigStore.searchSuggestion) return;

        clearTimeout(suggestionTimer);
        suggestionTimer = setTimeout(() => {
            const data: IRequestSearchSuggestion = {
                query: $state_searchString,
                maxHits: 5,
            };

            if ($state_selectedIndicesList.length)
                data.index = $state_selectedIndicesList
                    .toString()
                    .replace(", ", "");

            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.requestSuggestion, {
                    detail: data,
                }),
            );
        }, 500);
    };

    const isAutoSuggestionUp = () => {
        try {
            const wInnerHeight = window.innerHeight / 2;
            const elTop = txtSearch.getBoundingClientRect().top;

            if (elTop === 0) {
                isAutoSuggestionUpNow = false;
                return false;
            } else {
                isAutoSuggestionUpNow = elTop > wInnerHeight + 100;
                return elTop > wInnerHeight + 100;
            }
        } catch {
            isAutoSuggestionUpNow = false;
            return false;
        }
    };

    const speechChange = (e: any) => {
        state_searchString.set(e.detail);
        search();
    };
</script>

<div
    class="cs-input-wrapper"
    class:cs-mic-on={$ConfigStore.speechInputOn}
    class:cs-active-hints={$state_searchSuggestion?.length > 1 ||
        ($state_searchSuggestion.length == 1 &&
            $state_searchSuggestion[0].data.question != $state_searchString)}
    class:cs-auto-suggestion-up={isAutoSuggestionUpNow}
>
    <input
        bind:this={txtSearch}
        type="text"
        class="cs-search-input"
        placeholder={$_("search_input.placeholder")}
        bind:value={$state_searchString}
        maxlength={$ConfigStore.searchStringMaxLength}
        on:keyup={handleOnKeyUp}
    />

    {#if $state_searchSuggestion?.length > 1 || ($state_searchSuggestion.length == 1 && $state_searchSuggestion[0].data.question != $state_searchString)}
        <div class="cs-hint-wrapper" bind:this={searchSuggestionList}>
            <SuggestionComponent isautosuggestionup={isAutoSuggestionUp()} />
        </div>
    {/if}

    <button
        disabled={$state_searchString === $state_searchResult?.question ||
            $ConfigStore.searchStringMinLength >= $state_searchString?.length ||
            $ConfigStore.searchStringMaxLength <= $state_searchString?.length ||
            isSearchBusy}
        class="icon-arrow-right btn-search"
        title={$_("search_input.search_now")}
        on:click={search}
    />
    {#if $ConfigStore.speechInputOn}
        <InputSpeechRecognitionComponent />
    {/if}
    <!-- <button class="btn-search" alt="Search Now" on:click={search} /> -->
    <div />
    {#if $ConfigStore.searchStringMaxLength <= $state_searchString?.length}
        <div transition:fade|global class="cs-search-length-hint">
            {$_("search_input.error_question_long")}
        </div>
    {/if}

    {#if isMinStringMsg && $ConfigStore.searchStringMinLength >= $state_searchString?.length}
        <div transition:fade|global class="cs-search-length-hint">
            {$_("search_input.error_question_short")}
        </div>
    {/if}
</div>

<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}
-->
<style lang="scss">
    @import "./../../scss/components/_search-input";
</style>
