
import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { WindowsCustomEventTypes } from "../../enums/windows-custom-event.enum";
import type { IAnswer } from "../../interfaces/answer.interface";
import { state_busyApi, state_conversationResults, state_questionRelatedSearchSuggestions } from "../../store/state";
import { defaultLogger as logger } from "../../telemetry/logger";
import { GetQuestionRelatedSuggestions } from "../api/get-question-related-suggestions";
import { ConfigStore } from "../../store/config";

export class QuestionRelatedSuggestionsHandler {

    private abortController;

    constructor() {

        this.abort();
    }

    public async get(answer: IAnswer) {
        if (!get(ConfigStore).serverConfig?.question_prompts?.includes('suggested_search')
            || !answer.question_id
            || answer.question_id == get(state_questionRelatedSearchSuggestions)?.questionId

            /** avoid requesting when last message is greeting message */
            //|| get(state_conversationResults)[get(state_conversationResults)?.length - 1]?.is_greeting
            )
            return;

        this.clear();

        try {
            this.abortController?.abort();
            this.abortController = new AbortController();
            const res = await GetQuestionRelatedSuggestions(answer, this.abortController.signal, false);

            if (res.ok) {

                const data: any = await res.json();
                state_questionRelatedSearchSuggestions.set({ data: data.suggested_search.answer, questionId: answer.question_id });

            }

        } catch (e) {
            logger.error('QuestionRelatedSuggestionsHandler', e)
        }
    }

    private clear(): void {
        state_questionRelatedSearchSuggestions.set(null);
    }

    /** method to abort pending operation */
    private abort(): void {

        /** check for type of action */
        const abortNow = (e): void => {
            if (get(state_busyApi).includes(ApiTypes.getQuestionRelatedSuggestions))
                this.abortController?.abort();
        }

        [WindowsCustomEventTypes.requestPostOption].forEach(evt =>
            window.addEventListener(evt, abortNow)
        );
    }

}