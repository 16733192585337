
import { get } from "svelte/store";
import { WidgetType } from "../../enums/widget-type.enum";
import { ConfigStore } from "../../store/config";
import { state_indicesList } from "../../store/state";
import { defaultLogger as logger } from "../../telemetry/logger";
import { GetIndex } from "../api/get-indices";

export class IndicesHelper {

    constructor() { }

    public async get() {

        if ([WidgetType.chat, WidgetType.cb].includes(
            get(ConfigStore).widgetType))
            return;

        try {
            if (!get(ConfigStore).apiBaseUrl)
                logger.warn('IndicesHelper/get - invalid api base url');

            const res = await GetIndex();

            if (res?.ok) {
                const indicesList = await res.json();
                state_indicesList.set(indicesList.hits);
            }

        } catch (e) {
            logger.error('IndicesHelper/get', e)
        }


    }

}