import { writable } from "svelte/store";
import type { ApiTypes } from "../enums/api.enum";
import type { IAlternativeAnswer } from "../interfaces/alternative-answer.interface";
import type { IAnswer } from "../interfaces/answer.interface";
import type { IApiError } from "../interfaces/api-error.interface";
import type { ISearchFAQ } from "../interfaces/faq.interface";
import type { IIndicesItem } from "../interfaces/indices.interface";
import type { IQuestionRelatedSearchSuggestions } from "../interfaces/question-related-search-suggestions.interface";
import type { IUpdateRating } from "../interfaces/request-update-rating-question.interface";
import type { ISearchSuggestion } from "../interfaces/search-suggestion.interface";
import type { IServerConfig } from "../interfaces/server-config";
import type { ISessionStorageData } from "../interfaces/session-storage";

export const state_showSearch = writable<boolean>(false);
export const state_busyApi = writable<ApiTypes[]>([]);
export const state_apiErrors = writable<IApiError[]>([]);
export const state_searchString = writable<string>('');
export const state_searchResult = writable<IAnswer>(null);
export const state_conversationResults = writable<IAnswer[]>([]);
export const state_searchSuggestion = writable<ISearchSuggestion[]>([]);
export const state_faq = writable<ISearchFAQ[]>([]);
export const state_indicesList = writable<IIndicesItem[]>([]);
export const state_selectedIndicesList = writable<string[]>([]);
export const state_alternativeSearchResults = writable<IAlternativeAnswer[]>([]);
export const state_showAlternative = writable<boolean>(false);
export const state_answerRating = writable<IUpdateRating>(null);
export const state_questionRelatedSearchSuggestions = writable<IQuestionRelatedSearchSuggestions>(null);
export const state_showBadge = writable<boolean>(true);
export const state_sessionStorage = writable<ISessionStorageData>(null);
export const state_updatedAttributes = writable<any>(null);