import { waitLocale } from 'svelte-i18n'
import './i18n';
async function awaitLocale() {
    await waitLocale();
}

awaitLocale()
// export default app 
import cleverSearchFullPage from './full-page.svelte';
import cleverSearchChatBot from './chat-bot.svelte';
import cleverSearchChat from './chat.svelte'
import cleverSearchToggle from './lib/components/toggler.svelte';

// import cleverSearchResults from './lib/components/result-wrapper.svelte';
// import cleverSearchResultMain from './lib/components/result-content.svelte';
// import cleverRatingExtend from './lib/components/rating-extend.svelte';
// import cleverAnswerFilter from './lib/components/answer-filter.svelte';
// import cleverAnswerSource from './lib/components/answer-source.svelte';
// import cleverAnswerFilterIndices from './lib/components/answer-filter-indices.svelte';
// import CleverSearchSocialSharing from './lib/components/social-sharing.svelte';


// import cleverSearchInputRecognition from './lib/components/search-input-speech-recognition.svelte';
// import cleverSearchBusy from './lib/components/busy.svelte';
// import cleverRating from './lib/components/rating.svelte';
// import cleverAnswerAlternative from './lib/components/alternative-answer.svelte';
// import cleverSearchSuggestion from './lib/components/search-input-suggestion.svelte';
// import cleverSearchFaq from './lib/components/faq.svelte';
// import CleverSearchQuestionRelatedSearchSuggestions from './lib/components/question-related-search.svelte';
// import CleverSearchSourceRelatedSearchSuggestions from './lib/components/source-related-search-suggestions.svelte';
// import CleverSearchChatWrapper from './lib/components/chat-wrapper.svelte';
// import CleverSearchChatResultItem from './lib/components/chat-result-item.svelte';

import { SearchController } from './lib/controllers/search-controller';

import { ApplicationInsights, type ITelemetryItem } from "@microsoft/applicationinsights-web";
import { variables } from './variables';
import { defaultLogger } from './lib/telemetry/logger';

//console.log(variables.APP_INSIGHTS_CONNECTION_STRING);

const filteringFunction = (envelope: ITelemetryItem) => {
    switch (envelope.baseType.toLowerCase()) {
        // case 'remotedependencydata':
        //     for (const domain of [
        //         'clevertar.app', 'conversagent.app', 'azurewebsites.net'
        //     ]) {
        //         if (envelope.baseData.target.indexOf(domain) > -1) {
        //             console.log("tracked:");console.dir(envelope);
        //             return true;
        //         }
        //     }
        //     console.log("untracked:");console.dir(envelope);
        //     return false;
        //     break;
        // default:
        //     return true;
        //     break;
        default:
            //console.log("tracked:");console.dir(envelope);
            return true;
    }
};

const appInsights = new ApplicationInsights({
    config: {
        connectionString: variables.APP_INSIGHTS_CONNECTION_STRING,
        disableExceptionTracking: true,
        disableAjaxTracking: true,
        disableFetchTracking: true,
        loggingLevelTelemetry: 0
    },
});

appInsights.loadAppInsights();
appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
appInsights.addTelemetryInitializer(filteringFunction);
defaultLogger.setAppInsights(appInsights);

new SearchController();



