import type { IRequestSearchFaq } from "../../interfaces/request-search-faq.interface";
import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { addApiError, addBusyApi, removeBusyApi } from "../../helpers/handle-api";
import type { IRequestSearchSuggestion } from "../../interfaces/request-search-suggestion.interface";
import { ConfigStore } from "../../store/config";
import { state_apiErrors } from "../../store/state";
import { locale } from "svelte-i18n";
import { trackedFetch } from "../../telemetry/tracked-fetch";


/** function to fetch GET /1/suggestion api end point */
export async function GetSuggestions(reqVal: IRequestSearchSuggestion | IRequestSearchFaq, signal: AbortSignal): Promise<any> {

    let gqaLocale;

    try {

        addBusyApi(ApiTypes.getSuggestions);

        locale.subscribe(x => gqaLocale = x);

        state_apiErrors.set(get(state_apiErrors).filter(x => x.apiType !== ApiTypes.getSuggestions));

        let url = new URL(`${get(ConfigStore).apiBaseUrl}/1/suggestion`);

        if (reqVal?.query)
            url.searchParams.set('q', reqVal.query.toString());

        /*if (reqVal?.index)
            url.searchParams.set('index', reqVal.index);*/

        if (reqVal?.maxHits)
            url.searchParams.set('maxHits', reqVal.maxHits.toString());

        /*if (!!get(ConfigStore)?.serverConfig?.id)
            url.searchParams.set('config', get(ConfigStore)?.serverConfig?.id);*/

        if (!!get(ConfigStore)?.serverConfig?.tenant_id)
            url.searchParams.set('tenant', get(ConfigStore)?.serverConfig?.tenant_id);

        if (!!gqaLocale)
            url.searchParams.set('locale', gqaLocale)

        return trackedFetch(get(ConfigStore)?.serverConfig?.tenant_id, url.href, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            signal
        })
            .then((res) => {
                if (res?.ok)
                    return res;

                throw (res);
            })
            .catch((res) => {
                /** error api */
                addApiError({
                    apiType: ApiTypes.getSuggestions,
                    status: res?.status,
                    statusText: res?.statusText,
                    error: res?.error,
                    errorType: res?.type
                });

                throw (res);
            })
            .finally(() => {

                removeBusyApi(ApiTypes.getSuggestions);

            });

    } catch (e) {
        removeBusyApi(ApiTypes.getSuggestions);
        throw (e);
    }
}
