import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { addApiError, addBusyApi, removeBusyApi } from "../../helpers/handle-api";
import { state_apiErrors } from "../../store/state";
import { trackedFetch } from "../../telemetry/tracked-fetch";

/** function to fetch GET /1/option api end point */
export async function GetOption(
    configId: string,
    tenantId: string,
    apiBaseUrl: string
): Promise<any> {

    try {

        addBusyApi(ApiTypes.getOption);
        state_apiErrors.set(get(state_apiErrors).filter(x => x.apiType !== ApiTypes.getOption));

        let url = new URL(`${apiBaseUrl}/1/option`);

        if (configId) {
            url.searchParams.set('config', configId);
        }

        if (tenantId) {
            url.searchParams.set('tenant', tenantId);
        }

        return trackedFetch(tenantId, url.href, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (res?.ok)
                    return res;

                throw (res);
            })
            .catch((res) => {
                /** error api */
                addApiError({
                    apiType: ApiTypes.getOption,
                    status: res?.status,
                    statusText: res?.statusText,
                    error: res?.error,
                    errorType: res?.type
                });

                throw (res);
            })
            .finally(() => {
                removeBusyApi(ApiTypes.getOption);
            });

    } catch (e) {
        removeBusyApi(ApiTypes.getOption);
        throw (e);
    }
}
