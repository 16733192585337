
import type { IConfigStore } from "../../interfaces/store-config.interface";
import { get } from "svelte/store";
import { WidgetType } from "../../enums/widget-type.enum";
import { WindowsCustomEventTypes } from "../../enums/windows-custom-event.enum";
import { appendComponents } from "../../helpers/append-component";
import { patchFontIcoMoonFont } from "../../helpers/append-icoMoon";
import { updateConfigStore } from "../../helpers/update-config-store";
import { updateStateStore } from "../../helpers/update-state-store";
import type { IAnswer } from "../../interfaces/answer.interface";
import { ConfigStore } from "../../store/config";
import { state_conversationResults, state_searchString, state_showSearch } from "../../store/state";
import { defaultLogger as logger } from "../../telemetry/logger";
import { GetOption } from "../api/get-option";

export class ConfigHandler {

    private init: boolean;

    constructor() { }

    public async get(params: {
        configOptions: IConfigStore,
        serverConfig: { config: string; tenant: string }
    }) {

        try {

            const res = await GetOption(
                params?.serverConfig?.config || get(ConfigStore)?.serverConfig?.id,
                params?.serverConfig?.tenant || get(ConfigStore)?.serverConfig?.tenant_id,
                params?.configOptions.apiBaseUrl || get(ConfigStore).apiBaseUrl
            )

            if (res?.ok) {
                const data: any = await res.json();


                if (data?.widget?.config)
                    updateConfigStore({ ...data?.widget?.config });

                if (data?.widget?.state)
                    updateStateStore(data?.widget?.state);

                if (params) {
                    const config: IConfigStore = {
                        ...get(ConfigStore),
                        ...params.configOptions,
                        serverConfig: {
                            ...get(ConfigStore).serverConfig,
                            id: params?.serverConfig?.config, tenant_id: params?.serverConfig?.tenant,
                            name: data?.name,
                            conversation_prompt: data?.conversation_prompt,
                            question_prompts: data?.question_prompts,
                            document_prompts: data?.document_prompts
                        }

                    }
                    updateConfigStore(config);

                    window.dispatchEvent(
                        new CustomEvent(WindowsCustomEventTypes.configReady)
                    );

                    this.requestIndices();

                    this.requestGreeting();

                }

                this.isInit();

            }

        } catch (e) {
            logger.error('ConfigHandler', e);

            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.configError, {
                    detail: e,
                })
            );
        }
    }


    /** method to append widget to the DOM and load fonts */
    private isInit(): void {

        if (!this.init) {
            patchFontIcoMoonFont();
            appendComponents()
            this.init = true;
        }
    }

    private requestIndices(): void {
        switch (get(ConfigStore).widgetType) {
            case WidgetType.cb:
                if (get(state_showSearch))
                    window.dispatchEvent(
                        new CustomEvent(WindowsCustomEventTypes.requestIndices));
                break;

            default:
                window.dispatchEvent(
                    new CustomEvent(WindowsCustomEventTypes.requestIndices));
                break;
        }
    }

    private requestGreeting(): void {
        switch (get(ConfigStore).widgetType) {
            case WidgetType.chat:
                window.dispatchEvent(
                    new CustomEvent(WindowsCustomEventTypes.requestGreeting));
            default:
                break;
        }
    }

}